
import { Routes, Route } from 'react-router-dom';
import Navbar from '../components/Navbar';
import React, { lazy, Suspense } from 'react';
import { CartProvider } from '../components/Cartcomponet';

const Homepage = lazy(() => import('../pages/Homepage'));
const Contact = lazy(() => import('../pages/Contact'));
const Aboutus = lazy(() => import('../pages/Aboutus'));
const Bedroom = lazy(() => import('../pages/Bedroom'));
const Dining = lazy(() => import('../pages/Dining'));
const Electricals = lazy(() => import('../pages/Electricals'));
const Kitchen = lazy(() => import('../pages/Kitchen'));
const Lounge = lazy(() => import('../pages/Lounge'));
const Office = lazy(() => import('../pages/Office'));
const Viewproduct = lazy(() => import('../pages/Viewproduct'));
const Schoolfurniture = lazy(() => import('../pages/Schoolfurnture'));
const Cart = lazy(() => import('../pages/Cart'));
const Payment = lazy(() => import('../pages/Payment'));
const Ordersuccessful = lazy(() => import('../pages/Ordersuccessful'));



const Beds = lazy(() => import('../categories/Beds'));
const Cabinets = lazy(() => import('../categories/Cabinets'));
const Coffetables = lazy(() => import('../categories/Coffetables'));
const ExecutiveDesks = lazy(() => import('../categories/ExecutiveDesks'));
const Fridges = lazy(() => import('../categories/Fridges'));
const KitchenChairsUnites = lazy(() => import('../categories/KitchenChairsUnites'));
const OtherBedroom = lazy(() => import('../categories/OtherBedroom'));
const Otheroffice = lazy(() => import('../categories/Otheroffice'));
const OtherElectricals = lazy(() => import('../categories/OtherElectricals'));
const OtherKitchen = lazy(() => import('../categories/OtherKitchen'));
const Stoves = lazy(() => import('../categories/Stoves'));
const Suites = lazy(() => import('../categories/Suites'));
const TVs = lazy(() => import('../categories/TVs'));
const Wadrobes = lazy(() => import('../categories/Wadrobes'));
const Phones = lazy(() => import('../categories/Phones'));
const Microwaves = lazy(() => import('../categories/Microwaves'));
const Sofas = lazy(() => import('../categories/Sofas'));
const PlasmaStands = lazy(() => import('../categories/PlasmaStands'));
const OtherLounge = lazy(() => import('../categories/OtherLounge'));

const Swivelchairs = lazy(() => import('../categories/Swivelchairs'));
const Visitorschairs = lazy(() => import('../categories/Visitorschairs'));



const Routesfile = () => {
    return (

        <div>
            <Navbar />
            <Suspense fallback={<div className="loader-container">
                <div className="loader-image"></div>
                <div className="loader-spinner"></div>
            </div>}>

                <CartProvider>
                    <Routes>
                        <Route path="/" element={<Homepage />} />
                        <Route path="/Bedroom" element={<Bedroom />} />
                        <Route path="/Contact" element={<Contact />} />
                        <Route path="/Dining" element={<Dining />} />
                        <Route path="/Electricals" element={<Electricals />} />
                        <Route path="/Kitchen" element={<Kitchen />} />
                        <Route path="/Lounge" element={<Lounge />} />
                        <Route path="/Office" element={<Office />} />
                        <Route path="/Aboutus" element={<Aboutus />} />
                        <Route path="/school" element={<Schoolfurniture />} />
                        <Route path="/Viewproduct/:product_id" element={<Viewproduct />} />
                        <Route path="/cart" element={<Cart />} />
                        <Route path="/Payment" element={<Payment />} />
                        <Route path="/Ordersuccessful" element={<Ordersuccessful />} />


                        <Route path="/Beds" element={<Beds />} />
                        <Route path="/Cabinets" element={<Cabinets />} />
                        <Route path="/Coffetables" element={<Coffetables />} />
                        <Route path="/ExecutiveDesks" element={<ExecutiveDesks />} />
                        <Route path="/Fridges" element={<Fridges />} />
                        <Route path="/KitchenChairsUnites" element={<KitchenChairsUnites />} />
                        <Route path="/OtherBedroom" element={<OtherBedroom />} />
                        <Route path="/Otheroffice" element={<Otheroffice />} />
                        <Route path="/OtherElectricals" element={<OtherElectricals />} />
                        <Route path="/OtherKitchen" element={<OtherKitchen />} />
                        <Route path="/OtherLounge" element={<OtherLounge />} />

                        <Route path="/Swivelchairs" element={<Swivelchairs />} />
                        <Route path="/Visitorschairs" element={<Visitorschairs />} />

                        <Route path="/Stoves" element={<Stoves />} />
                        <Route path="/Suites" element={<Suites />} />
                        <Route path="/TVs" element={<TVs />} />
                        <Route path="/Wadrobes" element={<Wadrobes />} />
                        <Route path="/Phones" element={<Phones />} />
                        <Route path="/Microwaves" element={<Microwaves />} />
                        <Route path="/Sofas" element={<Sofas />} />
                        <Route path="/PlasmaStands" element={<PlasmaStands />} />
                    </Routes>
                </CartProvider>

            </Suspense>
        </div>

    )
}

export default Routesfile
