import React, { createContext, useReducer, useContext } from 'react';

const CartContext = createContext();

export const useCart = () => {
  return useContext(CartContext);
};

const cartReducer = (state, action) => {
  switch (action.type) {
    case 'ADD_TO_CART':
      // Logic to add a product to the cart
      return { ...state, products: [...state.products, action.payload] };

    case 'REMOVE_FROM_CART':
      // Logic to remove a product from the cart
      const updatedProducts = state.products.filter(product => product !== action.payload);
      return { ...state, products: updatedProducts };

    // Add other cases like 'REMOVE_FROM_CART', 'INCREMENT_PRODUCT', 'DECREMENT_PRODUCT' based on your requirements
    case 'UPDATE_QUANTITY':
      console.log('UPDATE_QUANTITY Action:', action);
      const updatedQuantityProducts = state.products.map((product) =>
        product.product_id === action.payload.productId
          ? { ...product, quantityToBuy: action.payload.quantityToBuy }
          : product
      );
      console.log('Updated Quantity Products:', updatedQuantityProducts);
      return { ...state, products: updatedQuantityProducts };
   
   
    default:
      return state;
  }
};

export const CartProvider = ({ children }) => {
  const [cart, dispatch] = useReducer(cartReducer, { products: [] });

  return (
    <CartContext.Provider value={{ cart, dispatch }}>
      {children}
    </CartContext.Provider>
  );
};
