import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import logo from "../assets/logo.png";

const Navbar = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const menuRef = useRef(null);

    const toggleMenu = () => {
        setIsMenuOpen((prevIsMenuOpen) => !prevIsMenuOpen);
    };

    const handleCategoryClick = (categoryName) => {
        setSelectedCategory((prevCategory) => (prevCategory === categoryName ? null : categoryName));
        // Close the menu after selecting a category
        if (!isMenuOpen) {
            setIsMenuOpen(true);
        }
    };

    useEffect(() => {
        const closeMenu = (e) => {
            if (
                menuRef.current &&
                !menuRef.current.contains(e.target) &&
                !e.target.closest('.md\\:flex') &&
                !e.target.closest('.flex.md\\:hidden') // Close the menu if clicked outside the menu button
            ) {
                setIsMenuOpen(false);
            }
        };

        document.addEventListener('click', closeMenu);

        return () => {
            document.removeEventListener('click', closeMenu);
        };
    }, [menuRef]);

    return (
        <div className='shadow-md fixed items-center top-0 z-50 bg-white text-[#BB0000] w-full md:w-[100%] h-[80px] justify-between flex px-4'>
            <Link to="/">
                <img src={logo} alt="Fourtech" className='flex  justify-start md:w-[150px] w-full h-[60px] md:h-full ' />
            </Link>
            <div className='md:flex hidden'>
                <ul className='flex gap-9 justify-center items-center font-bold text-lg'>


                    <li>
                        <button onClick={() => handleCategoryClick('/')}>
                            <Link to="/">Home</Link>
                        </button>
                    </li>
                    <li>
                        <button onClick={() => handleCategoryClick('AboutUs')}>
                            <Link to="/AboutUs">About Us</Link>
                        </button>
                    </li>
                    <li>
                        <button onClick={() => handleCategoryClick('Contact')}>
                            <Link to="/Contact">Contact</Link>
                        </button>
                    </li>
                    <li className='text-white'>
                        <button className='bg-[#BB0000] text-start text-white rounded-sm py-1 px-5' >
                            <Link to="/Cart">CART</Link>
                        </button>
                    </li>
                </ul>
                
            </div>
            <div className='flex md:hidden mr-4 ' ref={menuRef}>
                <Link to="/cart" className='mr-4 ' >
                    <ShoppingCartIcon style={{ fontSize: 32, color: '#454545' }} />
                </Link>
                <button onClick={toggleMenu}>
                    <MenuIcon style={{ fontSize: 32, color: '#454545' }} />
                </button>
                {isMenuOpen && (
                    <div className='fixed px-3 items-start top-[2%] right-1 w-[30%] flex flex-col h-full bg-white gap-4 text-lg' ref={menuRef}>

                        <button className='border-b-2 mt-3' onClick={() => handleCategoryClick('Office')}>
                            <span>Office</span>
                        </button>
                        <button className='border-b-2 mt-3' onClick={() => handleCategoryClick('Lounge')}>
                            <span>Lounge</span>
                        </button>
                        <button className='border-b-2 mt-3' onClick={() => handleCategoryClick('Bedroom')}>
                            <span>Bedroom</span>
                        </button>
                        <button className='border-b-2 mt-3' onClick={() => handleCategoryClick('Electricals')}>
                            <span>Electricals</span>
                        </button>
                        <button className='border-b-2 mt-3' onClick={() => handleCategoryClick('Kitchen')}>
                            <span>Kitchen</span>
                        </button>
                        <button className='border-b-2 mt-3' >
                            <span><Link
                                to="/Dining"
                            >
                                Dining
                            </Link></span>
                        </button>
                        <button className='border-b-2 mt-3' >
                            <span> <Link
                                to="/School"
                            >
                                School
                            </Link></span>
                        </button>
                        <button className='border-b-2 mt-3' onClick={() => handleCategoryClick('AboutUs')}>
                            <Link to="/AboutUs">About Us</Link>
                        </button>
                        <button className='border-b-2 mt-3' onClick={() => handleCategoryClick('Contact')}>
                            <Link to="/Contact">Contact</Link>
                        </button>
                        <button  className='border-b-2 mt-3 bg-[#BB0000] text-start text-white rounded-sm py-1 px-5' >
                            <Link to="/Cart">CART</Link>
                        </button>
                        {selectedCategory === 'Office' && (
                            <div className="flex fixed z-50 border bg-white p-3 gap-4 top-0 right-[31%] flex-col  mt-5">
                                <Link to="/ExecutiveDesks" className="block border-b-2 " onClick={() => handleCategoryClick('')}>
                                    Executive Desks
                                </Link>
                                <Link to="/Cabinets" className="block border-b-2" onClick={() => handleCategoryClick('')}>
                                    Cabinets
                                </Link>
                                <Link to="/Visitorschairs" className="block border-b-2" onClick={() => handleCategoryClick('')}>
                                    Visitors' chairs
                                </Link>
                                <Link to="/Swivelchairs" className="block border-b-2" onClick={() => handleCategoryClick('')}>
                                    Swivel chairs
                                </Link>
                                <Link to="/OtherOffice" className="block border-b-2" onClick={() => handleCategoryClick('')}>
                                    Other
                                </Link>
                            </div>
                        )}

                        {selectedCategory === 'Lounge' && (
                            <div className="flex fixed z-50 border bg-white p-3 gap-4 top-0 right-[31%] flex-col  mt-5">
                                <Link to="/Coffetables" className="block border-b-2" onClick={() => handleCategoryClick('')}>
                                    Coffee tables
                                </Link>
                                <Link to="/Sofas" className="block border-b-2" onClick={() => handleCategoryClick('')}>
                                    Sofas
                                </Link>

                            </div>
                        )}

                        {selectedCategory === 'Bedroom' && (
                            <div className="flex fixed z-50 border bg-white p-3 gap-4 top-0 right-[31%] flex-col  mt-5">
                                <Link to="/Beds" className="block border-b-2 " onClick={() => handleCategoryClick('')}>
                                    Beds
                                </Link>
                                <Link to="/Suites" className="block border-b-2" onClick={() => handleCategoryClick('')}>
                                    Bedroom suites
                                </Link>
                                <Link to="/Wardrobes" className="block border-b-2" onClick={() => handleCategoryClick('')}>
                                    Wardrobes
                                </Link>
                                <Link to="/OtherBedroom" className="block border-b-2" onClick={() => handleCategoryClick('')}>
                                    Other
                                </Link>

                            </div>
                        )}

                        {selectedCategory === 'Electricals' && (
                            <div className="flex fixed z-50 border bg-white p-3 gap-4 top-0 right-[31%] flex-col  mt-5">
                                <Link to="/TVs" className="block  border-b-2 " onClick={() => handleCategoryClick('')}>
                                    TVs
                                </Link>
                                <Link to="/Phones" className="block border-b-2" onClick={() => handleCategoryClick('')}>
                                    Phones
                                </Link>

                                <Link to="/OtherElectricals" className="block border-b-2" onClick={() => handleCategoryClick('')}>
                                    Other
                                </Link>

                            </div>
                        )}

                        {selectedCategory === 'Kitchen' && (
                            <div className="flex fixed z-50 border bg-white p-3 gap-4 top-0 right-[31%] flex-col  mt-5">
                                <Link to="/Stoves" className="block border-b-2 " onClick={() => handleCategoryClick('')}>
                                    Stoves
                                </Link>
                                <Link to="/Microwaves" className="block border-b-2" onClick={() => handleCategoryClick('')}>
                                    Microwaves
                                </Link>
                                <Link to="/KitchenChairsUnites" className="block border-b-2" onClick={() => handleCategoryClick('')}>
                                    Kitchen Units
                                </Link>
                                <Link to="/Fridges" className="block border-b-2" onClick={() => handleCategoryClick('')}>
                                    Fridges
                                </Link>
                                <Link to="/OtherKitchen" className="block border-b-2" onClick={() => handleCategoryClick('')}>
                                    Other
                                </Link>
                            </div>
                        )}



                    </div>
                )}
            </div>
        </div>
    );
};

export default Navbar;
