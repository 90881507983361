import React from "react";
import Routesfile from "./routes/Routesfile";

function App() {
  return (
    <Routesfile/>
  );
}

export default App;
